import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";

const ControlpanelPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('control_panel') + ' - ' + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <div className="ControlpanelPage p-component p-m-lg-4">
      <Panel>

      </Panel>
    </div>
  )
}
export default withTranslation('common')(ControlpanelPage);
