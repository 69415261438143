import React, {useState, useEffect, useRef} from 'react';
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {Menubar} from "primereact/menubar";
import {withTranslation} from 'react-i18next';
import LangselectComponent from "./LangselectComponent";
import {SplitButton} from "primereact/splitbutton";

const NavbarComponent = (props) => {

  const {t, i18n} = props;
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    let _menu = [
      {
        label: t('control_panel'),
        icon: 'pi pi-sliders-v',
        url: '/#/control-panel',
        className: 'control-panel'
      }
    ]
    if (process.env.REACT_APP_BRAND === 'felhomatrac') {
      _menu.push(
        {
          label: t('calendar'),
          icon: 'pi pi-calendar',
          url: '/#/calendar',
          className: '_FM_calendar'
        },
      )
    }
    setMenu(_menu)
  }, [])

  return (
    <nav>
      <>
        <Menubar
          style={{minHeight: '52px'}}
          start={
            <>
              <Link to={"/"}>
                <img src={require(".." + process.env.REACT_APP_LOGO_FILE)} height={"40"} className={"p-m-2"}/>
              </Link>
            </>
          }
          model={menu}
          className={"p-py-0"}
          end={
            <>
              <SplitButton label={t('profile')}
                           icon="pi pi-user"
                           className={"p-button-success p-ml-2"}
                           onClick={() => {

                           }}
                           model={[
                             {
                               label: t('profile'),
                               icon: 'pi pi-user',
                               command: () => {
                                 window.location.replace('/#/profile');
                                 window.App.forceUpdate()
                               }
                             },
                             {
                               label: t('users'),
                               icon: 'pi pi-users',
                               command: () => {
                                 window.location.replace('/#/users');
                                 window.App.forceUpdate()
                               }
                             },
                             {
                               label: t('operators'),
                               icon: 'pi pi-briefcase',
                               command: () => {
                                 window.location.replace('/#/operators');
                                 window.App.forceUpdate()
                               }
                             },
                             {
                               label: t('accommodations'),
                               icon: 'pi pi-home',
                               command: () => {
                                 window.location.replace('/#/accommodations');
                                 window.App.forceUpdate()
                               }
                             },
                             {
                               label: t('logout'),
                               icon: 'pi pi-sign-out',
                               command: () => {
                                 if (window.confirm(t('confirm_logout'))) {
                                   window.App.logout()
                                 }
                               }
                             },
                           ]}/>
              <LangselectComponent/>
            </>
          }
        />
      </>
    </nav>
  )
}
export default withTranslation('common')(NavbarComponent);
