import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Link} from "react-router-dom";
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import axios from "axios";
import LangselectComponent from "../components/LangselectComponent";

const LoginPage = (props) => {

  const [formData, setFormData] = useState({});
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(false);
  const {t, i18n} = props;

  const validate = (data) => {
    let errors = {};
    if (!data.email) {
      errors.email = t('this_input_cannot_be_empty');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = t('please_use_a_valid_email_address');
    }
    // if (!data.password) {
    //   errors.password = 'A jelszó nem lehet üres';
    // }
    return errors;
  };

  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData(data);
    axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "login_check",
      {
        username: data.email,
        password: data.password
      },
      {
        params: {},
        headers: {
          "Sys-name": localStorage.getItem('instanceId'),
          "Preferred-locale": localStorage.getItem('language')
        }
      })
      .then(response => {
        setLoading(false)
        localStorage.setItem('userToken', response.data.token)
        document.getElementById('body').classList.remove('outside')
        window.location.replace('/#/');
        window.App.forceUpdate()
      })
      .catch(error => {
        setLoading(false)
        window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
      })

  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    document.getElementById('body').classList.add('outside')
    document.title = t('login') + ' - ' + process.env.REACT_APP_TITLE;
    localStorage.removeItem('subdomain')
    localStorage.removeItem('instanceId')
    localStorage.removeItem('instanceName')
    if (window.location.hostname.split('.').slice(0, Number(process.env.REACT_APP_APP_HOST_DEPH)).join('.')) {
      localStorage.setItem('instanceId', window.location.hostname.split('.').slice(0, Number(process.env.REACT_APP_APP_HOST_DEPH)).join('.'))
      localStorage.setItem('subdomain', window.location.hostname.split('.').slice(0, Number(process.env.REACT_APP_APP_HOST_DEPH)).join('.'))
      window.App.forceUpdate()
    }
  }, []);

  return (
    <div className="LoginPage p-component">
      <img src={require(".." + process.env.REACT_APP_LOGO_FILE)} className="p-d-block p-my-5 p-mx-auto"/>
      <Panel>
        <div className={"p-grid p-mb-4"}>
          <div className={"p-col-6 p-lg-6 p-xl-6 p-col-align-center"}>
            <h3 className={"p-m-0"}>
              <i className={"pi pi-user"}></i> {t('login')}
            </h3>
          </div>
          <div className={"p-col-6 p-lg-6 p-xl-6 p-text-right"}>
            <LangselectComponent />
          </div>
        </div>
        <Form onSubmit={onSubmit}
              className="p-d-block p-mx-auto"
              initialValues={formData}
              validate={validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <Field name="email" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="email"
                           className={"" + classNames({'p-error': isFormFieldValid(meta)})}>{t('email')}</label>
                    <div className={"p-inputgroup"}>
                      <InputText id="email"
                                 {...input}
                                 tabIndex={1}
                                 value={formData.email}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     email: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      {localStorage.getItem('subdomain') === null &&
                      <Button type="button" className="p-button-success" icon={"pi pi-chevron-right"}
                              loading={loading}
                              onClick={() => {
                                setInstances([])
                                if (!Object.keys(validate(formData))[0]) {
                                  setLoading(true)
                                  axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "login-select",
                                    formData,
                                    {
                                      params: {},
                                      headers: {
                                        "Preferred-locale": localStorage.getItem('language')
                                      }
                                    })
                                    .then(response => {
                                      setLoading(false)
                                      if (response.data.data && response.data.data.items && response.data.data.items[0]) {
                                        setInstances(response.data.data.items)
                                      } else {
                                        window.App.toastShow('warn', t('warning'), t('no_instance_found_for_this_email'))
                                      }
                                    })
                                    .catch(error => {
                                      setLoading(false)
                                      window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                    })
                                }
                              }}
                      />
                      }
                    </div>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              {instances && instances[0] && localStorage.getItem('subdomain') === null &&
              <div className={"p-col-12"}>
                <Field name="instance" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="instance"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>{t('instance')}</label>
                    <span className="p-d-block">
                                <Dropdown {...input}
                                          tabIndex={2}
                                          onChange={(e) => {
                                            localStorage.setItem("instanceId", e.target.value)
                                            setTimeout(() => {
                                              localStorage.setItem(
                                                "instanceName",
                                                document.getElementById('instance').getElementsByClassName("p-inputtext")[0].innerHTML
                                              )
                                            }, 1000)

                                          }}
                                          value={localStorage.getItem('instanceId')}
                                          options={instances}
                                          optionLabel="system_name"
                                          optionValue="system_name_slugged"
                                          id={"instance"}
                                          emptyMessage={"Még nincs munkakörnyezet"}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                            </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
              {localStorage.getItem('instanceId') !== null &&
              <div className={"p-col-12"}>
                <Field name="password" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="password"
                           className={"" + classNames({'p-error': isFormFieldValid(meta)})}>{t('password')}</label>
                    <Password id="password"
                              tabIndex={3}
                              {...input}
                              toggleMask
                              feedback={false}
                              value={formData.password}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  password: e.target.value
                                })
                              }}
                              className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
            </div>
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
                {localStorage.getItem('instanceId') !== null &&
                <>
                  <Link to={"/password-reset"} className={"p-d-block"}>{t('forgotten_password')}</Link>
                </>
                }
                {process.env.REACT_APP_REGISTRATION === 'enabled' &&
                <Link to={"/registration"} className={"p-d-block"}>{t('registration')}</Link>
                }
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                {formData.email && localStorage.getItem('instanceId') !== null && formData.password ? (
                  <Button type="submit"
                          label={t('login')}
                          className="p-button-success"
                          icon={"pi pi-check"}
                          loading={loading} disabled={submitting}/>
                ) : (
                  <Button type="submit"
                          label={t('login')}
                          className="p-button-success"
                          icon={"pi pi-check"}
                          loading={loading} disabled/>
                )}
              </div>
            </div>
          </form>
        )}/>
      </Panel>
    </div>
  )
}
export default withTranslation('common')(LoginPage);
