import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import axios from "axios";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import moment from "moment";
import {Calendar} from "primereact/calendar";
import {addLocale} from 'primereact/api';

import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../../assets/css/felhomatrac-calendar.css';
import {Eventcalendar} from '@mobiscroll/react';
import {Dropdown} from "primereact/dropdown";

function _FM_CalendarComponent(props) {

  const {t} = props;
  const [selectedDayBefore, setSelectedDayBefore] = useState(6)
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now() - 24 * 3600 * (selectedDayBefore * 1000)));

  addLocale('default', {
    firstDayOfWeek: 1,
    dayNames: [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')],
    dayNamesShort: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
    dayNamesMin: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
    monthNames: [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'),
      t('oct'), t('nov'), t('dec')],
    monthNamesShort: [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'),
      t('oct'), t('nov'), t('dec')],
    today: t('today'),
    clear: t('delete')
  });

  useEffect(() => {
  }, [])

  return (
    <div className={""}>

      <div className={"p-grid"}>
        <div className={"p-col-12 p-lg-6 p-col-align-center"}>
          <Button label={"-14"}
                  className={"p-mr-2 p-button-warning"}
                  onClick={() => {
                    let _dayBefore = selectedDayBefore + 14
                    setSelectedDayBefore(_dayBefore)
                    setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                  }}/>
          <Button label={"-7"}
                  className={"p-mr-2 p-button-warning"}
                  onClick={() => {
                    let _dayBefore = selectedDayBefore + 7
                    setSelectedDayBefore(_dayBefore)
                    setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                  }}/>
          <Button label={"-2"}
                  className={"p-mr-2 p-button-warning"}
                  onClick={() => {
                    let _dayBefore = selectedDayBefore + 2
                    setSelectedDayBefore(_dayBefore)
                    setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                  }}/>
          <Button label={t('today')}
                  className={"p-mr-2 p-button-info"}
                  onClick={() => {
                    setSelectedDayBefore(-0)
                    setSelectedDate(new Date(Date.now() - 24 * 3600 * (-0 * 1000)))
                  }}/>
          <Button label={"+2"}
                  className={"p-mr-2 p-button-success"}
                  onClick={() => {
                    let _dayBefore = selectedDayBefore - 2
                    setSelectedDayBefore(_dayBefore)
                    setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                  }}/>
          <Button label={"+7"}
                  className={"p-mr-2 p-button-success"}
                  onClick={() => {
                    let _dayBefore = selectedDayBefore - 7
                    setSelectedDayBefore(_dayBefore)
                    setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                  }}/>
          <Button label={"+14"}
                  className={"p-mr-2 p-button-success"}
                  onClick={() => {
                    let _dayBefore = selectedDayBefore - 14
                    setSelectedDayBefore(_dayBefore)
                    setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                  }}/>
          <Calendar value={selectedDate}
                    format={'yy-mm-dd'}
                    locale={"default"}
                    showIcon
                    onChange={(e) => {
                      setSelectedDayBefore(0)
                      setSelectedDate(e.value)
                    }}/>
        </div>
        <div className={"p-col-12 p-lg-6 p-col-align-center p-text-right"}>
          <Button aria-label={t('sum')}
                  className={"p-ml-2 p-button-success "}
                  onClick={() => {

                  }}>
            <span>&#8721;</span>
          </Button>
          <Button label={t('category')}
                  icon={"pi pi-eye-slash"}
                  className={"p-ml-2 p-button-warning"}
                  onClick={() => {

                  }}/>
          <Button label={t('accommodation')}
                  icon={"pi pi-eye-slash"}
                  className={"p-ml-2 p-button-warning"}
                  onClick={() => {

                  }}/>
          <Dropdown options={[
            {name: 'Szálláshely 2', code: 'szallas2'},
          ]}
                    optionLabel="name"
                    optionValue="code"
                    onChange={(e) => {
                    }}
                    className={"p-ml-2"}
                    value={'szallas2'}
          />
          <Button label={t('bills')}
                  icon={"pi pi-list"}
                  className={"p-ml-2 p-button-warning"}
                  onClick={() => {

                  }}/>
          <Button label={t('groups')}
                  icon={"pi pi-users"}
                  className={"p-ml-2 p-button-success"}
                  onClick={() => {

                  }}/>
          <Button label={t('reservation')}
                  icon={"pi pi-plus"}
                  className={"p-ml-2 p-button-success"}
                  onClick={() => {

                  }}/>
        </div>
      </div>
      <Eventcalendar
        width={'100%'}
        dataTimezone="utc"
        displayTimezone="Europe/Budapest"
        dragToCreate={false}
        dragToMove={false}
        dragToResize={false}
        eventDelete={true}
        showControls={false}
        clickToCreate={false}
        onCellClick={(date) => {}}
        onCellDoubleClick={(date) => {
          window.App.setState({
            sidebarEventForm: true,
          })
        }}
        renderMonth={() => {
        }}
        renderDay={(day) => {
          return (
            <div
              className={"day p-text-center " + moment.utc(day.date).local().format('ddd').toLowerCase() + " is-active-" + day.isActive}
              data-today={moment.utc(day.date).local().format('YYYY-MM-DD') == moment.utc(new Date(Date.now())).local().format('YYYY-MM-DD')}
              data-selected={moment.utc(day.date).local().format('YYYY-MM-DD') == moment.utc(selectedDate).local().format('YYYY-MM-DD')}>
              <span className={"p-d-block"}>
                {moment.utc(day.date).local().format('DD')}
              </span>
              <span className={"p-d-block"}>
                {t(moment.utc(day.date).local().format('ddd').toLowerCase())}
              </span>
              <span className={"p-d-block"}>
                {moment.utc(day.date).local().format('YY')}
                &nbsp;
                {t(moment.utc(day.date).local().format('MMM').toLowerCase())}
              </span>
            </div>
          )
        }}
        renderResourceHeader={() => {
          return (
            <div className={"header"}>
              <label htmlFor="search"
                     className={""}>{t('search')}</label>
              <span className="p-d-block">
                      <InputText id="search"
                                 onChange={(e) => {

                                 }}
                                 className={"w-100 p-inputtext-sm"}
                      />
                    </span>
            </div>
          )
        }}
        renderResource={(resource) => {
          return (
            <div className={"resource-item " + resource.type}>
              <span className={""}>
                {resource.name}
              </span>
            </div>
          )
        }}
        renderScheduleEvent={(event) => {
          console.log(event)
          if(event && event.original && event.original.status && event.original.status == 'occupancy') {
            return (
              <div className={"event " + event.original.status + " " + event.currentResource.type}>
                {event.title}
              </div>
            )
          } else {
            return (
              <div className={"event " + event.original.status + " " + event.currentResource.type}>
              <span className={"p-badge " + event.original.billingStatus}>
                {event.original.reservationId}
              </span>
                &nbsp;{event.title}
                {/*&nbsp;{event.original.contact}*/}
                {/*&nbsp;{event.original.guests}*/}
              </div>
            )
          }
        }}
        defaultSelectedDate={selectedDate}
        selectedDate={selectedDate}
        refDate={selectedDate}
        responsive={{
          xsmall: {
            view: {
              timeline: {
                type: 'day',
                resolutionHorizontal: 'day',
                size: 2,
              }
            }
          },
          small: {
            view: {
              timeline: {
                type: 'day',
                resolutionHorizontal: 'day',
                size: 4,
              }
            }
          },
          medium: {
            view: {
              timeline: {
                type: 'day',
                resolutionHorizontal: 'day',
                size: 8,
              }
            }
          },
          large: {
            view: {
              timeline: {
                type: 'day',
                resolutionHorizontal: 'day',
                size: 10,
              }
            }
          },
          xlarge: {
            view: {
              timeline: {
                type: 'day',
                resolutionHorizontal: 'day',
                size: 12,
              }
            }
          },
          custom: { // Custom breakpoint
            breakpoint: 1480,
            view: {
              timeline: {
                type: 'day',
                resolutionHorizontal: 'day',
                size: 16,
              }
            }
          },
          custom2: { // Custom breakpoint
            breakpoint: 1680,
            view: {
              timeline: {
                type: 'day',
                resolutionHorizontal: 'day',
                size: 19,
              }
            }
          },
          custom3: { // Custom breakpoint
            breakpoint: 1920,
            view: {
              timeline: {
                type: 'day',
                resolutionHorizontal: 'day',
                size: 22,
              }
            }
          }
        }}
        data={[
          {
            id:1,
            start: '2024-10-11T00:00',
            end: '2024-10-11T24:00',
            title: '1 / 1',
            status: 'occupancy',
            contact: '',
            guests: '',
            reservationId: '',
            billingStatus: '',
            resource: 1,
          },
          {
            id:2,
            start: '2024-10-02T15:00',
            end: '2024-10-05T10:00',
            title: '2',
            contact: 'Elemér Tihamér',
            guests: 'Elemér Tihamér, Barbi Barbara',
            status: 'demand',
            reservationId: '113',
            billingStatus: '',
            resource: 13,
          },
          {
            id:3,
            start: '2024-10-05T10:00',
            end: '2024-10-08T15:00',
            title: '1',
            contact: 'Barbi',
            guests: 'Barbi Barbara',
            status: 'demand dna',
            reservationId: '114',
            billingStatus: '',
            resource: 13,
          },
          {
            id:4,
            start: '2024-10-10T15:00',
            end: '2024-10-15T10:00',
            title: '3',
            contact: 'Jozsef László',
            guests: 'Elemér Tihamér, Barbi Barbara, Akár Kicsoda',
            status: 'confirmed',
            reservationId: '115',
            billingStatus: '',
            resource: 14,
          },
          {
            id:5,
            start: '2024-10-12T15:00',
            end: '2024-10-14T10:00',
            title: '2',
            contact: 'Akár Kicsoda',
            guests: 'Elemér Tihamér, Barbi Barbara',
            status: 'confirmed dna',
            reservationId: '116',
            billingStatus: '',
            resource: 13,
          },
          {
            id:6,
            start: '2024-10-14T15:00',
            end: '2024-10-16T10:00',
            title: '2 / 2v',
            contact: '',
            guests: 'Elemér Tihamér, Barbi Barbara',
            status: 'arrived hasBeds hasBeds-3',
            reservationId: '117',
            billingStatus: 'unpaid',
            resource: 11,
          },
          {
            id:7,
            start: '2024-10-10T15:00',
            end: '2024-10-14T10:00',
            title: '2 / 1v',
            contact: 'Barbi',
            guests: 'Barbi Barbara',
            status: 'left',
            reservationId: '118',
            billingStatus: 'paid',
            resource: 111,
          },
          {
            id:8,
            start: '2024-10-10T15:00',
            end: '2024-10-15T10:00',
            title: '2 / 2v',
            contact: 'Barbi',
            guests: 'Elemér Tihamér, Barbi Barbara',
            status: 'confirmed',
            reservationId: '120',
            resource: 12,
          },
        ]}
        resources={[
          {
            id: 1,
            name: 'Kategória A',
            type: 'category',
            children: [
              {
                id: 11,
                name: 'Szálláshely 1',
                type: 'accommodation',
                children: [
                  {
                    id: 111,
                    name: 'Ágy 1',
                    type: 'bed',
                  },
                  {
                    id: 112,
                    name: 'Ágy 2',
                    type: 'bed',
                  },
                  {
                    id: 113,
                    name: 'Ágy 3',
                    type: 'bed',
                  },
                ]
              },
              {
                id: 12,
                name: 'Szálláshely 2',
                type: 'accommodation',
              },
              {
                id: 13,
                name: 'Szálláshely 3',
                type: 'accommodation',
              },
              {
                id: 14,
                name: 'Szálláshely 4',
                type: 'accommodation',
              },
            ]
          },
        ]}
      />
    </div>
  );
}

export default withTranslation('common')(_FM_CalendarComponent);
