import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";

import _FM_CalendarComponent from "../components/_FM_calendar/_FM_CalendarComponent";
import {Sidebar} from "primereact/sidebar";

const _FM_CalendarPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('calendar') + ' - ' + process.env.REACT_APP_TITLE;
  }, []);

  return (
    <div className="_FM_CalendarPage p-component p-m-lg-4">
      <_FM_CalendarComponent />
      <Sidebar position="right" visible={window.App.state.sidebarEventForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarEventForm: false,
               })}>
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_CalendarPage);
